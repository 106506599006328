var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container",attrs:{"id":"content"}},[_c('div',{staticClass:"user-icon"}),(_vm.isLogin)?_c('div',{staticClass:"user-info-box",on:{"click":function($event){return _vm.$router.push({ name: 'MemberProfile' })}}},[_c('div',{staticClass:"user-avatar-box"},[_c('img',{attrs:{"src":_vm.user.avatar}})]),_c('div',{staticClass:"user-body"},[_c('div',{staticClass:"user-nickname"},[_vm._v(_vm._s(_vm.user.nick_name))]),_c('div',{staticClass:"role-name"},[_vm._v(" "+_vm._s(_vm.user.role ? _vm.user.role.name : "免费会员")+" ")])]),_vm._m(0)]):_c('div',{staticClass:"user-info-box",on:{"click":_vm.goLogin}},[_vm._m(1),_vm._m(2),_vm._m(3)]),_c('div',{staticClass:"user-banner-item"},[_c('div',{staticClass:"banner-body"},[(_vm.isLogin)?_c('div',{staticClass:"item",on:{"click":function($event){return _vm.$router.push({ name: 'MemberOrder' })}}},[_vm._m(4),_vm._m(5)]):_c('div',{staticClass:"item",on:{"click":_vm.goLogin}},[_vm._m(6),_vm._m(7)]),(_vm.isLogin)?_c('div',{staticClass:"item",on:{"click":function($event){return _vm.$router.push({ name: 'Messages' })}}},[_vm._m(8),(_vm.newStatus)?_c('i',{staticClass:"count"}):_vm._e(),_vm._m(9)]):_c('div',{staticClass:"item",on:{"click":_vm.goLogin}},[_vm._m(10),_vm._m(11)]),_c('div',{staticClass:"item",on:{"click":function($event){return _vm.$router.push({ name: 'MemberSetting' })}}},[_vm._m(12),_vm._m(13)])])]),_c('tech-support'),_c('nav-footer',{attrs:{"type":"me"}})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"user-back"},[_c('img',{staticStyle:{"width":"15px","height":"15px"},attrs:{"src":require("../../assets/img/new/back.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"user-avatar-box"},[_c('img',{attrs:{"src":require("../../assets/img/default_avatar.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"user-body"},[_c('div',{staticClass:"login-button"},[_vm._v("请登录")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"user-back"},[_c('img',{staticStyle:{"width":"15px","height":"15px"},attrs:{"src":require("../../assets/img/new/back.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"icon"},[_c('div',{staticClass:"icon-img"},[_c('img',{attrs:{"src":require("../../assets/img/new/order.png")}})]),_c('div',{staticClass:"name"},[_vm._v("我的订单")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"arrow-icon"},[_c('img',{attrs:{"src":require("../../assets/img/new/back.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"icon"},[_c('div',{staticClass:"icon-img"},[_c('img',{attrs:{"src":require("../../assets/img/new/order.png")}})]),_c('div',{staticClass:"name"},[_vm._v("我的订单")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"arrow-icon"},[_c('img',{attrs:{"src":require("../../assets/img/new/back.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"icon"},[_c('div',{staticClass:"icon-img"},[_c('img',{attrs:{"src":require("../../assets/img/new/message.png")}})]),_c('div',{staticClass:"name"},[_vm._v("我的消息")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"arrow-icon"},[_c('img',{attrs:{"src":require("../../assets/img/new/back.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"icon"},[_c('div',{staticClass:"icon-img"},[_c('img',{attrs:{"src":require("../../assets/img/new/message.png")}})]),_c('div',{staticClass:"name"},[_vm._v("我的消息")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"arrow-icon"},[_c('img',{attrs:{"src":require("../../assets/img/new/back.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"icon"},[_c('div',{staticClass:"icon-img"},[_c('img',{attrs:{"src":require("../../assets/img/new/setting.png")}})]),_c('div',{staticClass:"name"},[_vm._v("关于平台")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"arrow-icon"},[_c('img',{attrs:{"src":require("../../assets/img/new/back.png")}})])
}]

export { render, staticRenderFns }